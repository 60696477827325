import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "panasonic_haircare" */ '@/pages/homePage.vue'
      )
  },
  {
    path: '/cookie-policy',
    name: 'cookiePolicy',
    component: () =>
      import(
        /* webpackChunkName: "panasonic_cookiePolicy" */ '@/pages/cookiePolicy.vue'
      )
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "panasonic_privacypolicy" */ '@/pages/privacyPolicy.vue'
      )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'button--active',
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
